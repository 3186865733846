<script>
import InvoiceService from "@/services/InvoiceService";
import VContractHistory from "@/components/Momentum/Contracts/VContractHistory";
import ContractBoxes from "@/components/Momentum/Contracts/Contract/ContractBoxes";
import VAssets from "@/components/Momentum/Contracts/VAssets";
import VBudget from "@/components/Momentum/Contracts/VBudget";
import VInvoicePlan from "@/components/Momentum/Contracts/VInvoicePlan";
import VManualInvoice from "@/components/Momentum/Contracts/VManualInvoice";
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import VContractProjectForm from "@/components/Forms/VContractProjectForm";
import ProjectService from "@/services/ProjectService";
import Messages from "@/mixins/Messages";
import EcService from "@/services/EcService";

export default {
    name: "VContract",
    components: {
        VContractHistory,
        ContractBoxes,
        VAssets,
        VBudget,
        VInvoicePlan,
        VManualInvoice,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        VContractProjectForm,
    },
    mixins: [Messages],
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showHistory: false,
            unfoldTerminated: false,
            showEditOptions: false,
            showInvoicePlan: false,
            widgetLoading: false,
            dates: {},
            showProjectModal: false,
            projectContractContext: null,
            btnMarking: false,
            internalNoteModal: false,
            ec_products: [],
        };
    },
    computed: {
        /**
         *
         */
        hasActiveCase() {
            if (
                this.contract.is_terminated &&
                parseInt(this.contract.project_id) > 0 &&
                parseInt(this.contract.project.status) === 5
            ) {
                return true;
            }

            return false;
        },
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.fetchNextDates();
        this.fetchEcProducts();
    },
    methods: {
        /**
         * Fetch next dates for widgets
         */
        async fetchNextDates() {
            try {
                this.widgetLoading = true;
                const r = await InvoiceService.contractNextDates(
                    this.contract.id
                );
                this.dates = r.data.data;
                //console.log(this.dates);
            } catch (e) {}

            this.widgetLoading = false;
        },
        /**
         * Show invoicing plan
         */
        showPlan() {
            this.showInvoicePlan = true;
        },
        /**
         * Methods:
         * - quick
         * - full
         * - override (old method that allows change of everything w/o making a new version)
         */
        handleEditCommand(editMethod) {
            this.$emit("edit-contract", this.contract, editMethod);
        },
        createProject(contract_id) {
            this.projectContractContext = contract_id;
            this.showProjectModal = true;
        },

        /**
         * Mark connected case as finalized
         */
        async markCaseAsFinalized() {
            this.$askDeleteQuestion(
                this.$t("contract.confirm_marking_admin_case_as_finalized"),
                this.performCaseFinalization
            );
        },
        async performCaseFinalization() {
            try {
                this.btnMarking = true;
                const payload = {
                    id: this.contract.project_id,
                    status: 99,
                };

                const { data } = await ProjectService.PUT(payload);
                this.$emit("reload");
            } catch (e) {}

            this.btnMarking = false;
        },
        showInternalNote() {
            this.internalNoteModal = true;
        },
        formatText(text) {
            // Replace newline characters with <br> tags
            return text.replace(/\n/g, "<br>");
        },
        async fetchEcProducts() {
            try {
                const r = await EcService.ecProducts();
                this.ec_products = r.data.data;
            } catch (e) {}
        },
        matchProduct(id) {
            let res = this.ec_products.find((el) => el.value == id);
            if (typeof res === "undefined") {
                return "---";
            } else {
                return res.label;
            }
        },
    },
};
</script>

<template>
    <card
        class="contract"
        body-classes="px-0"
        mini-header-classes="text-center"
        :class="{ 'is-terminated': contract.is_terminated }"
    >
        <template v-if="contract.is_terminated && unfoldTerminated === false">
            <!-- terminated contract card -->
            <template slot="miniHeader"
                ><span>{{
                    $t("contract.this_contract_is_terminated_as_of", {
                        date: $d(new Date(contract.termination_date), "short"),
                    })
                }}</span>
                <div class="text-danger mt-2" v-if="hasActiveCase">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{
                        $t(
                            "contracts.notice_terminated_contract_has_active_case"
                        )
                    }}
                </div></template
            >

            <div class="row px-4">
                <div class="col-sm-4 col-xs-12">
                    <div class="contract__description">
                        <div class="contract__description__type">
                            <i :class="`far ${contract.asset_type.icon}`"></i>
                            {{ contract.contract_type_text }}
                        </div>
                        <div class="mt-1">
                            {{ $t("contract.id_number") }}: {{ contract.id }}
                        </div>
                        {{ $t("contract.contract_dates") }}
                        {{ $d(new Date(contract.from_date), "short") }} -
                        <template v-if="contract.to_date"
                            >{{ $d(new Date(contract.to_date), "short") }}
                        </template>
                        <template v-else
                            ><i class="fal fa-infinity"></i
                        ></template>

                        <div class="">
                            {{ $t("contract.version") }}:
                            {{ contract.contract_version }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-12">
                    <div class="text-bold">
                        {{ $t("contract.termination_reason") }}
                    </div>
                    <template v-if="contract.termination_note">
                        {{ contract.termination_note }}
                    </template>
                    <template v-else>---</template>

                    <div
                        class="alert alert-danger alert-outline mt-4"
                        v-if="hasActiveCase"
                    >
                        {{
                            $t(
                                "contract.contract_has_active_case_review_and_close"
                            )
                        }}
                    </div>
                    <div class="text-right" v-if="hasActiveCase && ACL_RW">
                        <base-button
                            size="sm"
                            type="danger"
                            @click="markCaseAsFinalized"
                            :loading="btnMarking"
                            >{{
                                $t("contract.mark_case_as_finalized")
                            }}</base-button
                        >
                        <router-link
                            :to="{
                                name: 'ProjectDisplay',
                                params: {
                                    project_id: contract.project_id,
                                },
                            }"
                            class="btn btn-white btn-sm"
                            >{{ $t("contract.go_to_admin_case") }}</router-link
                        >
                    </div>
                </div>

                <div class="col-sm-2 col-xs-12 text-right">
                    <base-button
                        size="sm"
                        type="secondary"
                        @click="unfoldTerminated = !unfoldTerminated"
                        >{{ $t("contract.unfold_terminated") }}</base-button
                    >
                </div>
            </div>
        </template>

        <!-- Active contract starts here -->
        <template v-else>
            <template slot="miniHeader" v-if="contract.termination_date">
                <span class="text-danger">
                    {{
                        $t("contract.this_contract_has_termination_date", {
                            date: $d(
                                new Date(contract.termination_date),
                                "short"
                            ),
                        })
                    }}</span
                >
                <span v-if="contract.termination_note" class="ml-3 small">{{
                    contract.termination_note
                }}</span>
            </template>
            <div class="row px-mg">
                <div class="col-lg-4 col-sm-12 contract__header">
                    <div class="col-lg-12 col-md-9 col-sm-9 col-xs-12">
                        <div class="contract__description">
                            <div class="contract__description__type">
                                <i
                                    :class="`far ${contract.asset_type.icon} fa-2x`"
                                ></i>
                                {{ contract.contract_type_text }}
                            </div>
                            <div class="mt-2">
                                {{ $t("contract.id_number") }}:
                                {{ contract.id }}
                            </div>
                            {{ $t("contract.contract_dates") }}
                            {{ $d(new Date(contract.from_date), "short") }} -
                            <template v-if="contract.to_date"
                                >{{ $d(new Date(contract.to_date), "short") }}
                            </template>
                            <template v-else
                                ><i class="fal fa-infinity"></i
                            ></template>

                            <div class="">
                                {{ $t("contract.version") }}:
                                {{ contract.contract_version }}
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-3 col-sm-3 col-xs-12">
                        <div class="contract__controls">
                            <a
                                target="_blank"
                                v-if="contract.sharepoint_url"
                                :href="contract.sharepoint_url"
                                ><i class="far fa-file-pdf"></i
                            ></a>

                            <span class="a-click disabled" v-else>
                                <i
                                    class="far fa-file-pdf"
                                    :title="$t('contract.no_pdf_link')"
                                ></i>
                            </span>

                            <el-dropdown
                                v-if="ACL_RW"
                                @command="handleEditCommand"
                                trigger="click"
                                placement="bottom-start"
                            >
                                <span
                                    class="a-click ml-3"
                                    @click="showEditOptions = !showEditOptions"
                                >
                                    <i class="far fa-pencil"></i>
                                </span>

                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="quick">
                                        {{ $t("contract.quick_edit") }}
                                    </el-dropdown-item>
                                    <el-dropdown-item command="full">
                                        {{ $t("contract.full_edit") }}
                                    </el-dropdown-item>
                                    <el-dropdown-item command="override">
                                        <span class="text-danger">
                                            <i
                                                class="far fa-exclamation-triangle"
                                            ></i>
                                            {{ $t("contract.override_edit") }}
                                        </span>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <span
                                v-else
                                class="a-click ml-3"
                                @click="handleEditCommand('quick')"
                            >
                                <i class="far fa-eye"></i>
                            </span>

                            <span
                                v-if="contract.has_revisions"
                                class="a-click ml-3"
                                @click="showHistory = true"
                                :title="$t('contract.show_revisions')"
                            >
                                <i class="far fa-history"></i>
                            </span>
                            <!-- Project -->

                            <span
                                class="a-click ml-3"
                                :title="$t('contract.go_to_connected_project')"
                                v-if="contract.project_id > 0"
                                @click="
                                    $router.push({
                                        name: 'ProjectDisplay',
                                        params: {
                                            project_id: contract.project_id,
                                        },
                                    })
                                "
                            >
                                <i class="far fa-clipboard-list-check"></i>
                            </span>
                            <base-button
                                v-else
                                @click="createProject(contract.id)"
                                size="sm"
                                class="ml-3"
                                type="primary"
                                :outline="true"
                                ><i class="far fa-clipboard-list-check"></i>
                                {{ $t("contract.create_case") }}</base-button
                            >
                        </div>
                        <!-- .contract-controls -->
                    </div>
                </div>
                <div class="col-lg-8 col-sm-12">
                    <contract-boxes
                        :contract="contract"
                        :widget-loading="widgetLoading"
                        :dates="dates"
                        @show-plan="showPlan()"
                    />
                </div>
                <div class="col-12 mt-2">
                    <div class="row">
                        <div class="col">
                            <div class="small">
                                {{ $t("contract.economic_product") }}:
                                {{ matchProduct(contract.economic_product) }}
                            </div>

                            <div class="small">
                                {{ $t("contract.invoice_prefix") }}:
                                <v-empty :text="contract.invoice_prefix_text" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="small">
                                {{ $t("contract.internal_note") }}:
                                <v-empty
                                    :text="contract.internal_note"
                                    :truncate="24"
                                    @expand="showInternalNote"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <v-budget :contract="contract" />
            <v-manual-invoice :contract="contract" />
            <v-assets :contract="contract" />

            <portal to="modals">
                <v-contract-project-form
                    v-if="showProjectModal"
                    :contract-id="projectContractContext"
                    :context="{ empty: true }"
                    @close="showProjectModal = false"
                />

                <modal
                    v-if="showHistory"
                    size="xl"
                    :show="true"
                    @close="showHistory = false"
                    class="contract-history-modal"
                >
                    <template slot="header">
                        {{ $t("contract.revision_history") }}
                    </template>

                    <template slot="default">
                        <v-contract-history :contract="contract" />
                    </template>
                </modal>

                <modal
                    v-if="showInvoicePlan"
                    size="xl"
                    :show="true"
                    @close="showInvoicePlan = false"
                    class="invoice-preview-modal"
                >
                    <template slot="header">
                        {{ $t("contract.invoicing_plan") }}
                    </template>

                    <template slot="default">
                        <v-invoice-plan :contract_id="contract.id" />
                    </template>
                </modal>
            </portal>
        </template>
        <portal to="modals">
            <modal
                v-if="internalNoteModal"
                :show="true"
                @close="internalNoteModal = false"
            >
                <template slot="header">
                    {{ $t("contract.internal_note") }}
                </template>

                <div v-html="formatText(contract.internal_note)"></div>
            </modal>
        </portal>
    </card>
</template>

