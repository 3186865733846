<script>
import VAssets from "@/components/Momentum/Contracts/VAssets";
import ProjectService from "@/services/ProjectService";
import EcService from "@/services/EcService";
import VContractProjectForm from "@/components/Forms/VContractProjectForm";
import MGConsts from "@/mixins/MGConsts";
import VContractEditForm from "@/components/Forms/VContractEditForm";

export default {
    props: {
        contract: {}
    },
    mixins: [MGConsts],
    components: { VAssets, VContractProjectForm, VContractEditForm },
    data() {
        return {
            internalNoteModal: false,
            ec_products: [],
            showProjectModal: false,
            showEditContractModal: false,
            layouts: {},
            termsOfPayment: {}
        };
    },
    computed: {
        /**
         *
         */
        hasActiveCase() {
            if (
                this.contract.is_terminated &&
                parseInt(this.contract.project_id) > 0 &&
                parseInt(this.contract.project.status) === 5
            ) {
                return true;
            }

            return false;
        },
        hasInvoicingOptions: function() {
            let check = [
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
                "invoice_override_product_text",
                "invoice_extra_text"
            ];

            let show = false;

            check.forEach(value => {
                if (this.contract[value]) {
                    show = true;
                }
            });

            //console.log("hasinvoice", show);

            return show;
        }
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.fetchEconomicParams();
        this.fetchEcProducts();
    },
    methods: {
        /**
         * Fetch economic params
         */
        async fetchEconomicParams() {
            try {
                const r = await EcService.ecLayoutsAndTerms();
                this.layouts = r.data.data.layouts_hash;
                this.termsOfPayment = r.data.data.terms_of_payment_hash;
            } catch (e) {}
        },
        /**
         * Close edit modal and reload after save
         */
        closeSaveContractModal() {
            this.$emit("reload-contract");
            this.showEditContractModal = false;
        },
        /**
         * Edit contract modal
         */
        editContract() {
            this.showEditContractModal = true;
        },
        /**
         * Create project modal
         */
        createProject() {
            this.showProjectModal = true;
        },

        /**
         * Mark connected case as finalized
         */
        async markCaseAsFinalized() {
            this.$askDeleteQuestion(
                this.$t("contract.confirm_marking_admin_case_as_finalized"),
                this.performCaseFinalization
            );
        },
        async performCaseFinalization() {
            try {
                this.btnMarking = true;
                const payload = {
                    id: this.contract.project_id,
                    status: 99
                };

                const { data } = await ProjectService.PUT(payload);
                this.$emit("reload");
            } catch (e) {}

            this.btnMarking = false;
        },
        showInternalNote() {
            this.internalNoteModal = true;
        },
        formatText(text) {
            // Replace newline characters with <br> tags
            return text.replace(/\n/g, "<br>");
        },
        async fetchEcProducts() {
            try {
                const r = await EcService.ecProducts();
                this.ec_products = r.data.data;
            } catch (e) {}
        },
        matchProduct(id) {
            let res = this.ec_products.find(el => el.value == id);
            if (typeof res === "undefined") {
                return "---";
            } else {
                return res.label;
            }
        }
    }
};
</script>

<template>
    <div class="contract-general-info">
        <div class="row px-4">
            <!-- Type & co -->
            <div class="col-4">
                <div class="">
                    <i
                        :class="`far ${contract.asset_type.icon} fa-2x mr-2`"
                    ></i>
                    {{ contract.contract_type_text }}
                </div>

                <div class="mt-4 smaller-font">
                    <b>{{ $t("contract.id_number") }}:</b>
                    {{ contract.id }}
                </div>

                <div class="smaller-font">
                    <b>{{ $t("contract.contract_dates") }}</b>
                    {{ $d(new Date(contract.from_date), "short") }} -
                    <template v-if="contract.to_date"
                        >{{ $d(new Date(contract.to_date), "short") }}
                    </template>
                    <template v-else><i class="fal fa-infinity"></i></template>
                </div>

                <div class="smaller-font">
                    <b>{{ $t("contract.payment_frequency") }}:</b>
                    {{ mgPaymentFrequencyHash[contract.frequency] }}
                </div>

                <div class="smaller-font">
                    <b>{{ $t("contract.payment_strategy") }}:</b>
                    {{ mgPaymentStrategyHash[contract.payment_strategy] }}
                </div>

                <div class="smaller-font">
                    <b>{{ $t("contract.finance_responsible") }}:</b>
                    {{ contract.finance_user.name }}
                </div>

                <div class="smaller-font">
                    <b>{{ $t("contract.operations_responsible") }}:</b>
                    {{ contract.operations_user.name }}
                </div>

                <div class="mt-3">
                    <base-button type="primary" @click="editContract" size="sm" v-if="ACL_RW"
                        ><i class="far fa-pencil"></i>
                        {{ $t("contract.edit") }}</base-button
                    >
                </div>
            </div>
            <!-- Products & co -->
            <div class="col-4">
                <dl>
                    <dt>
                        {{ $t("contract.currency") }}
                    </dt>
                    <dd>{{ contract.currency }}</dd>

                    <dt>
                        {{ $t("contract.economic_product") }}
                    </dt>
                    <dd>{{ matchProduct(contract.economic_product) }}</dd>

                    <dt>
                        {{ $t("contract.invoice_prefix") }}
                    </dt>
                    <dd><v-empty :text="contract.invoice_prefix_text" /></dd>

                    <template v-if="contract.invoice_heading">
                        <dt>{{ $t("contract.custom_invoice_heading") }}</dt>
                        <dd>
                            {{ contract.invoice_heading }}
                        </dd>
                    </template>

                    <template v-if="contract.invoice_text1">
                        <dt>{{ $t("contract.custom_invoice_text1") }}</dt>
                        <dd>
                            {{ contract.invoice_text1 }}
                        </dd>
                    </template>

                    <template v-if="contract.invoice_text2">
                        <dt>{{ $t("contract.custom_invoice_text2") }}</dt>
                        <dd>
                            {{ contract.invoice_text2 }}
                        </dd>
                    </template>

                    <template v-if="contract.invoice_term_of_payment">
                        <dt>
                            {{ $t("contract.custom_invoice_terms_of_payment") }}
                        </dt>
                        <dd>
                            {{
                                termsOfPayment[contract.invoice_term_of_payment]
                            }}
                        </dd>
                    </template>

                    <template v-if="contract.invoice_layout">
                        <dt>{{ $t("contract.custom_invoice_layout") }}</dt>
                        <dd>
                            {{ layouts[contract.invoice_layout] }}
                        </dd>
                    </template>
                </dl>
            </div>
            <!-- Case & docs -->
            <div class="col-4">
                <dl>
                    <dt>
                        {{ $t("contract.internal_note") }}
                    </dt>
                    <dd>
                        <v-empty
                            :text="contract.internal_note"
                            :truncate="24"
                            @expand="showInternalNote"
                        />
                    </dd>

                    <dt>
                        {{ $t("contract.additional_resources") }}
                    </dt>
                    <dd>
                        <a
                            target="_blank"
                            class="a-click"
                            v-if="contract.sharepoint_url"
                            :href="contract.sharepoint_url"
                            ><i class="far fa-file-pdf"></i
                        ></a>

                        <span class="a-click disabled" v-else>
                            <i
                                class="far fa-file-pdf"
                                :title="$t('contract.no_pdf_link')"
                            ></i>
                        </span>

                        <span
                            class="a-click ml-3"
                            :title="$t('contract.go_to_connected_project')"
                            v-if="contract.project_id > 0"
                            @click="
                                $router.push({
                                    name: 'ProjectDisplay',
                                    params: {
                                        project_id: contract.project_id
                                    }
                                })
                            "
                        >
                            <i class="far fa-clipboard-list-check"></i>
                        </span>
                        <base-button
                            v-else
                            @click="createProject"
                            size="sm"
                            class="ml-3"
                            type="primary"
                            :outline="true"
                            ><i class="far fa-clipboard-list-check"></i>
                            {{ $t("contract.create_case") }}</base-button
                        >
                    </dd>
                </dl>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <v-assets :contract="contract" />
            </div>
        </div>

        <portal to="modals">
            <v-contract-edit-form
                v-if="showEditContractModal"
                :context="contract"
                @close="showEditContractModal = false"
                @close-refresh="closeSaveContractModal"
            />

            <v-contract-project-form
                v-if="showProjectModal"
                :contract-id="contract.id"
                :context="{ empty: true }"
                @close="showProjectModal = false"
            />

            <modal
                v-if="internalNoteModal"
                :show="true"
                @close="internalNoteModal = false"
            >
                <template slot="header">
                    {{ $t("contract.internal_note") }}
                </template>

                <div v-html="formatText(contract.internal_note)"></div>
            </modal>
        </portal>
    </div>
</template>

<style lang="scss">
.contract-general-info {
    .smaller-font {
        font-size: 13px;
    }
    dt {
        font-size: 13px;
    }
    dd {
        font-size: 13px;
    }
}
</style>
